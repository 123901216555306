import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Button,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { apiGenerator } from 'utils/apiHelpers';

const LessonCategoryFormModal = ({ open, setOpen, onSuccess, lessonCategory, parentCategory }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [image, setImage] = React.useState(null);
  const [imageName, setImageName] = React.useState('Upload Image');
  const [premium, setPremium] = React.useState(false);
  const [hasSubCategories, setHasSubCategories] = React.useState(false);

  useEffect(() => {
    if (!open) {
      setTitle('');
      setDescription('');
      setImage(null);
      setImageName('Upload Image');
      setPremium(false);
      setOpen(false);
      setHasSubCategories(false);
    }
  }, [open]);

  useEffect(() => {
    if (lessonCategory) {
      setTitle(lessonCategory?.title);
      setDescription(lessonCategory?.description);
      setPremium(lessonCategory?.premium);
      setHasSubCategories(lessonCategory?.has_sub_categories);
    }
  }, [lessonCategory]);

  const createLessonCategoryMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/lesson-categories', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
      createLessonCategoryMutation.reset();
    },
  });

  const updateLessonCategoryMutation = useMutation({
    mutationFn: (data) => apiGenerator('put')(`/admin/lesson-categories/${lessonCategory.id}`, data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const handleSubmit = () => {
    const data = new FormData();
    if (image) data.append('banner', image);
    data.append('title', title);
    data.append('description', description);
    data.append('premium', premium);
    data.append('has_sub_categories', hasSubCategories);
    if (parentCategory) data.append('parent_category_id', parentCategory.id);

    if (lessonCategory) {
      updateLessonCategoryMutation.mutate(data);
      return;
    }

    createLessonCategoryMutation.mutate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Create Category</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
          sx={{ width: '30rem' }}
        >
          <TextField
            autoFocus
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Description"
            type="text"
            fullWidth
            multiline
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <FormControlLabel
            control={<Checkbox label="Premium" checked={premium} onChange={(e) => setPremium(e.target.checked)} />}
            label="Premium"
          />

          <FormControlLabel
            control={
              <Checkbox
                label="Has Sub Categories"
                checked={hasSubCategories}
                onChange={(e) => setHasSubCategories(e.target.checked)}
              />
            }
            label="Has Sub Categories"
          />

          <Button variant="contained" component="label">
            {imageName}
            <input
              type="file"
              hidden
              onChange={(e) => {
                setImageName(e.target.files[0].name);
                setImage(e.target.files[0]);
              }}
            />
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createLessonCategoryMutation.isLoading || updateLessonCategoryMutation.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

LessonCategoryFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  lessonCategory: PropTypes.object || null,
};

export default LessonCategoryFormModal;
