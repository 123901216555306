import axios from 'axios';
import { getAccessTokenFromLocalStorage } from 'helpers/storage.helpers';

export const apiGenerator = (type) => {
  const headers = {
    'content-type': 'multipart/form-data',
  };

  const localAccessToken = getAccessTokenFromLocalStorage();
  if (localAccessToken) {
    headers.Authorization = `Bearer ${localAccessToken}`;
  }

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers,
  };

  switch (type) {
    case 'get':
      return (url, options = {}) => axios.get(url, { ...defaultOptions, ...options });
    case 'post':
      return (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options });
    case 'patch':
      return (url, data, options = {}) => axios.patch(url, data, { ...defaultOptions, ...options });
    case 'put':
      return (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options });
    case 'delete':
      return (url, data, options = {}) => axios.delete(url, { data, ...defaultOptions, ...options });
    default:
      return null;
  }
};
