import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Container,
  Typography,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, MoreHoriz, OpenInNew } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import { useParams } from 'react-router-dom';
import LessonPhaseStepFormModal from 'components/LessonPhaseStepFormModal/LessonPhaseStepFormModal';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

export default function LessonPhaseShowPage() {
  const { id } = useParams();

  const [open, setOpen] = useState(null);
  const [selectedLessonPhaseStep, setSelectedLessonPhaseStep] = useState(null);
  const [showLessonPhaseStepFormModal, setShowLessonPhaseStepFormModal] = useState(false);

  const {
    data: lessonPhaseData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['lesson-phase', id],
    queryFn: () => apiGenerator('get')(`/admin/lesson-phases/${id}`),
  });

  const { lesson_phase_steps: lessonPhaseSteps, title } = lessonPhaseData?.data?.attributes || {};

  const destroyLessonPhaseStepMutation = useMutation({
    mutationFn: (id) => apiGenerator('delete')(`/admin/lesson-phase-steps/${id}`),
    onSuccess: () => {
      refetch();
    },
  });

  const increaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/lesson-phase-steps/${id}/increase-order`),
    onSuccess: () => {
      refetch();
    },
  });

  const decreaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/lesson-phase-steps/${id}/decrease-order`),
    onSuccess: () => {
      refetch();
    },
  });

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const rows =
    lessonPhaseSteps?.map((step) => ({
      id: step.id,
      order: step.attributes.order,
      title: step.attributes.title,
      description: step.attributes.description,
      media_url: step.attributes.media_url,
      media_type: step.attributes.media_type,
    })) || [];

  const columns = [
    { field: 'id', headerName: 'ID', width: 0 },
    { field: 'order', headerName: 'Order', sortable: false, flex: 0.2 },
    { field: 'title', headerName: 'Title', flex: 0.2 },
    { field: 'description', headerName: 'Description', flex: 0.2 },
    {
      field: 'media_url',
      headerName: 'Media',

      renderCell: ({ row }) => (
        <Box sx={{ width: 60, height: 60, borderRadius: 1, overflow: 'hidden' }}>
          {row.media_type?.includes('video') ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video style={{ height: 60 }} src={row.media_url} autoPlay loop muted alt="no-media" />
          ) : (
            <img src={row.media_url} alt="no-media" />
          )}
        </Box>
      ),
      flex: 0.2,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.2,
      sortable: false,
      renderCell: ({ row }) => (
        <Box display={'flex'}>
          <IconButton
            onClick={() => {
              increaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            onClick={() => {
              decreaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setOpen(e.currentTarget);
              setSelectedLessonPhaseStep(row);
            }}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Steps | Petzip</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lesson Phase: {title}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setSelectedLessonPhaseStep(null);
              setShowLessonPhaseStepFormModal(true);
            }}
          >
            New Step
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter rowHeight={100} />
            </div>
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpen(null);
            setShowLessonPhaseStepFormModal(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            destroyLessonPhaseStepMutation.mutate(selectedLessonPhaseStep.id);
            setOpen(null);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {showLessonPhaseStepFormModal && (
        <LessonPhaseStepFormModal
          open={showLessonPhaseStepFormModal}
          setOpen={setShowLessonPhaseStepFormModal}
          onSuccess={() => {
            refetch();
          }}
          lessonPhaseId={id}
          lessonPhaseStep={selectedLessonPhaseStep}
        />
      )}

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
