import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Button,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { apiGenerator } from 'utils/apiHelpers';

const BannerFormModal = ({ open, setOpen, onSuccess, banner }) => {
  const [image, setImage] = React.useState(null);

  useEffect(() => {
    if (!open) {
      setImage(null);
    }
  }, [open]);

  const createBannerMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/banners', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
      createBannerMutation.reset();
    },
  });

  const handleSubmit = () => {
    const data = new FormData();
    if (image) data.append('image', image);

    createBannerMutation.mutate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Create Category</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
          sx={{ width: '30rem' }}
        >
          <Button variant="contained" component="label">
            {image?.name || 'Upload Image'}
            <input
              type="file"
              hidden
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createBannerMutation.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

BannerFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  banner: PropTypes.object,
};

export default BannerFormModal;
