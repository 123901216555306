import { Dialog, DialogContent, DialogTitle, Stack, TextField, Button, DialogActions } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { apiGenerator } from 'utils/apiHelpers';

const LessonPhaseFormModal = ({ open, setOpen, onSuccess, lessonPhase, lessonId }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  useEffect(() => {
    if (!open) {
      setTitle('');
      setDescription('');
    }
  }, [open]);

  useEffect(() => {
    if (lessonPhase) {
      setTitle(lessonPhase?.title);
      setDescription(lessonPhase?.description);
    }
  }, [lessonPhase]);

  const createLessonPhaseMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/lesson-phases', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const updateLessonPhaseMutation = useMutation({
    mutationFn: (data) => apiGenerator('put')(`/admin/lesson-phases/${lessonPhase.id}`, data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const handleSubmit = () => {
    const data = new FormData();

    data.append('title', title);
    data.append('description', description);
    data.append('lesson_id', lessonId);

    if (lessonPhase) {
      updateLessonPhaseMutation.mutate(data);
      return;
    }

    createLessonPhaseMutation.mutate(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>New Phase</DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={3}
            sx={{ width: '30rem' }}
          >
            <TextField
              autoFocus
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Description"
              type="text"
              fullWidth
              multiline
              variant="standard"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

LessonPhaseFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  lessonPhase: PropTypes.object,
  lessonId: PropTypes.number,
};

export default LessonPhaseFormModal;
