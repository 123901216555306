import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Button,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { apiGenerator } from 'utils/apiHelpers';

const LessonPhaseStepFormModal = ({ open, setOpen, onSuccess, lessonPhaseStep, lessonPhaseId }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [media, setMedia] = React.useState(null);

  useEffect(() => {
    if (!open) {
      setTitle('');
      setDescription('');
      setMedia(null);
    }
  }, [open]);

  useEffect(() => {
    if (lessonPhaseStep) {
      setTitle(lessonPhaseStep?.title);
      setDescription(lessonPhaseStep?.description);
    }
  }, [lessonPhaseStep]);

  const createLessonPhaseStepMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/lesson-phase-steps', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const updateLessonPhaseStepMutation = useMutation({
    mutationFn: (data) => apiGenerator('put')(`/admin/lesson-phase-steps/${lessonPhaseStep.id}`, data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const handleSubmit = () => {
    const data = new FormData();

    if (media) data.append('media', media);
    data.append('title', title);
    data.append('description', description);
    data.append('lesson_phase_id', lessonPhaseId);

    if (lessonPhaseStep) {
      updateLessonPhaseStepMutation.mutate(data);
      return;
    }

    createLessonPhaseStepMutation.mutate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>New Step</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={3}
          sx={{ width: '30rem' }}
        >
          <TextField
            autoFocus
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Description"
            type="text"
            fullWidth
            multiline
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Button variant="contained" component="label">
            {media?.name || 'Upload Media'}
            <input
              type="file"
              hidden
              onChange={(e) => {
                setMedia(e.target.files[0]);
              }}
            />
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createLessonPhaseStepMutation.isLoading || updateLessonPhaseStepMutation.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

LessonPhaseStepFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  lessonPhaseStep: PropTypes.object,
  lessonPhaseId: PropTypes.number,
};

export default LessonPhaseStepFormModal;
