import { Delete } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Button,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { apiGenerator } from 'utils/apiHelpers';

const LessonFormModal = ({ open, setOpen, onSuccess, lesson, lessonCategoryId }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [tips, setTips] = React.useState([]);
  const [difficulty, setDifficulty] = React.useState('easy');
  const [media, setMedia] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const [premium, setPremium] = React.useState(false);

  const tipRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setTitle('');
      setDescription('');
      setTips([]);
      setDifficulty('easy');
      setMedia(null);
      setBanner(null);
      setPremium(false);
    }
  }, [open]);

  useEffect(() => {
    if (lesson) {
      setTitle(lesson?.title);
      setDescription(lesson?.description);
      setPremium(lesson?.premium);
      if (lesson?.tips) setTips(lesson?.tips);
      setDifficulty(lesson?.difficulty);
    }
  }, [lesson]);

  const createLessonMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/lessons', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const updateLessonMutation = useMutation({
    mutationFn: (data) => apiGenerator('put')(`/admin/lessons/${lesson.id}`, data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const handleSubmit = () => {
    const data = new FormData();
    if (banner) data.append('banner', banner);
    if (media) data.append('media', media);
    data.append('title', title);
    data.append('description', description);
    tips.forEach((tip) => {
      data.append('tips[]', tip);
    });
    data.append('difficulty', difficulty);
    data.append('premium', premium);
    data.append('lesson_category_id', lessonCategoryId);

    if (lesson) {
      updateLessonMutation.mutate(data);
      return;
    }

    createLessonMutation.mutate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>New Lesson</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={3}
          sx={{ width: '30rem' }}
        >
          <TextField
            autoFocus
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Description"
            type="text"
            fullWidth
            multiline
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Box sx={{ display: 'flex' }}>
            <TextField inputRef={tipRef} label="Tips" type="text" fullWidth variant="standard" />
            <Button
              variant="text"
              component="label"
              onClick={() => {
                setTips([...tips, tipRef.current.value]);
                tipRef.current.value = '';
              }}
            >
              Add
            </Button>
          </Box>

          <Box sx={{ display: 'flex', marginLeft: '16px' }}>
            <ul>
              {tips?.map((tip, index) => (
                <Box key={index} sx={{ display: 'flex' }}>
                  <li>{tip}</li>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setTips(tips.filter((t) => t !== tip));
                    }}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Box>
              ))}
            </ul>
          </Box>

          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Difficulty</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={difficulty}
              label="Difficulty"
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <MenuItem value="easy">Easy</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="hard">Hard</MenuItem>
              <MenuItem value="expert">Expert</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={<Checkbox label="Premium" checked={premium} onChange={(e) => setPremium(e.target.checked)} />}
            label="Premium"
          />

          <Button variant="contained" component="label">
            {banner?.name || 'Upload Banner'}
            <input
              type="file"
              hidden
              onChange={(e) => {
                setBanner(e.target.files[0]);
              }}
            />
          </Button>

          <Button variant="contained" component="label">
            {media?.name || 'Upload Media'}
            <input
              type="file"
              hidden
              onChange={(e) => {
                setMedia(e.target.files[0]);
              }}
            />
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={createLessonMutation.isLoading || updateLessonMutation.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

LessonFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  lesson: PropTypes.object,
  lessonCategoryId: PropTypes.string.isRequired,
};

export default LessonFormModal;
