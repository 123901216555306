import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Container,
  Typography,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, MoreHoriz, OpenInNew } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import LessonPhaseFormModal from 'components/LessonPhaseFormModal/LessonPhaseFormModal';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

export default function LessonShowPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [selectedLessonPhase, setSelectedLessonPhase] = useState(null);
  const [refetchCount, setRefetchCount] = useState(0);
  const [showLessonPhaseFormModal, setShowLessonPhaseFormModal] = useState(false);

  const { data: lessonData, isLoading } = useQuery({
    queryKey: ['lessons', refetchCount, id],
    queryFn: () => apiGenerator('get')(`/admin/lessons/${id}`),
  });

  const { lesson_phases: lessonPhases, title } = lessonData?.data?.attributes || {};

  const destroyLessonPhaseMutation = useMutation({
    mutationFn: (id) => apiGenerator('delete')(`/admin/lesson-phases/${id}`),
    onSuccess: () => {
      setRefetchCount((prev) => prev + 1);
    },
  });

  const increaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/lesson-phases/${id}/increase-order`),
    onSuccess: () => {
      setRefetchCount((prev) => prev + 1);
    },
  });

  const decreaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/lesson-phases/${id}/decrease-order`),
    onSuccess: () => {
      setRefetchCount((prev) => prev + 1);
    },
  });

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const rows =
    lessonPhases?.map((category) => ({
      id: category.id,
      order: category.attributes.order,
      title: category.attributes.title,
      description: category.attributes.description,
      stepCount: category?.attributes?.lesson_phase_steps?.length || 0,
    })) || [];

  const columns = [
    { field: 'id', headerName: 'ID', width: 0 },
    { field: 'order', headerName: 'Order', sortable: false, flex: 0.2 },
    { field: 'title', headerName: 'Title', flex: 0.2 },
    { field: 'description', headerName: 'Description', flex: 0.2 },
    { field: 'stepCount', headerName: 'Step Count', flex: 0.2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.2,
      sortable: false,
      renderCell: ({ row }) => (
        <Box display={'flex'}>
          <IconButton
            onClick={() => {
              navigate(`/lesson-phases/${row.id}`);
            }}
          >
            <OpenInNew />
          </IconButton>
          <IconButton
            onClick={() => {
              increaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            onClick={() => {
              decreaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setOpen(e.currentTarget);
              setSelectedLessonPhase(row);
            }}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Lesson | Petzip</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lesson: {title}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setSelectedLessonPhase(null);
              setShowLessonPhaseFormModal(true);
            }}
          >
            New Phase
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter rowHeight={100} />
            </div>
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpen(null);
            setShowLessonPhaseFormModal(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            destroyLessonPhaseMutation.mutate(selectedLessonPhase.id);
            setOpen(null);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {showLessonPhaseFormModal && (
        <LessonPhaseFormModal
          open={showLessonPhaseFormModal}
          setOpen={setShowLessonPhaseFormModal}
          onSuccess={() => {
            setRefetchCount((prev) => prev + 1);
            setOpen(null);
          }}
          lessonPhase={selectedLessonPhase}
          lessonId={id}
        />
      )}

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
