import {
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { apiGenerator } from 'utils/apiHelpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { createConsumer } from '@rails/actioncable';
import { getAccessTokenFromLocalStorage } from 'helpers/storage.helpers';
// import { ChatMessageDto } from '../../model/ChatMessageDto';

export default function ChatPage() {
  const ENTER_KEY_CODE = 13;
  const { id } = useParams();
  const { user } = useAuth();
  const actionCableRef = useRef(null);
  const [messages, setMessages] = useState([]);

  const chatListContainer = useRef(null);

  const [message, setMessage] = useState('');

  useEffect(() => {
    chatListContainer.current.scrollTop = chatListContainer.current.scrollHeight;
  }, [messages]);

  const {
    data: chatData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['banners'],
    queryFn: () => apiGenerator('get')(`/admin/chats/${id}`),
    onSuccess: (data) => {
      setMessages(data.data.attributes.messages);
    },
  });

  const chatUser = chatData?.data?.attributes?.user;

  useEffect(() => {
    connectSocket();

    return () => {
      actionCableRef.current.disconnect();
      actionCableRef.current = null;
    };
  }, []);

  const createMessageMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/messages', data),
    onSuccess: () => {
      createMessageMutation.reset();
    },
  });

  const connectSocket = () => {
    const token = getAccessTokenFromLocalStorage();
    actionCableRef.current = createConsumer(`${process.env.REACT_APP_WEBSOCKET_BASE_URL}/cable?token=${token}`);

    actionCableRef.current.subscriptions.create(
      { channel: 'ChatChannel', chat_id: id },
      {
        received: (message) => {
          setMessages((messages) => [...messages, message]);
        },
        disconnected: (e) => {
          console.log(e);
        },
      }
    );
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEnterKey = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    createMessageMutation.mutate({ chat_id: id, content: message });

    setMessage('');
  };

  const listChatMessages = messages?.map((message, index) => {
    const isUsersMessage = message.attributes.user_id === parseInt(user?.id, 10);

    return (
      <ListItem
        key={index}
        sx={{
          width: 'max-content',
          maxWidth: '50%',
          marginLeft: isUsersMessage && 'auto',
          marginRight: !isUsersMessage && 'auto',
          marginTop: '0.5rem',
          backgroundColor: '#f3f3f3',
          borderRadius: '4px',
          textAlign: 'right',
        }}
      >
        <ListItemText primary={`${message.attributes.content}`} />
      </ListItem>
    );
  });

  return (
    <>
      <Container>
        <Paper elevation={5}>
          <Box p={3}>
            <Typography variant="h4" gutterBottom>
              {chatUser?.attributes?.email}
            </Typography>
            <Divider />
            <Grid container spacing={4} alignItems="center">
              <Grid id="chat-window" xs={12} item>
                <List
                  ref={chatListContainer}
                  sx={{ maxHeight: '30rem', overflowY: 'scroll' }}
                  id="chat-window-messages"
                >
                  {listChatMessages}
                </List>
              </Grid>
              <Grid xs={11} item>
                <FormControl fullWidth>
                  <TextField
                    onChange={handleMessageChange}
                    onKeyDown={handleEnterKey}
                    value={message}
                    label="Type your message..."
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid xs={1} item>
                <IconButton onClick={sendMessage} aria-label="send" color="primary">
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
