import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Container, Typography, IconButton, Box, Backdrop, CircularProgress } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import Scrollbar from '../components/scrollbar';

export default function ChatsPage() {
  const navigate = useNavigate();

  const { data: chatsData, isLoading } = useQuery({
    queryKey: ['chats'],
    queryFn: () => apiGenerator('get')('/admin/chats'),
  });

  const rows =
    chatsData?.data?.map((chat) => ({
      id: chat.id,
      email: chat.attributes.user.attributes.email,
    })) || [];

  const columns = [
    { field: 'email', headerName: 'Email', sortable: false, flex: 0.8 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.3,
      sortable: false,
      renderCell: ({ row }) => (
        <Box display={'flex'}>
          <IconButton onClick={() => navigate(`/chats/${row.id}`)}>
            <OpenInNew />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Chats | Petzip</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Chats
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <Box sx={{ height: 500, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter />
            </Box>
          </Scrollbar>
        </Card>
      </Container>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
