import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Container,
  Typography,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import BannerFormModal from 'components/BannerFormModal/BannerFormModal';
import { ArrowUpward, ArrowDownward, MoreHoriz, OpenInNew } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

export default function BannersPage() {
  const [open, setOpen] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [showBannerFormModal, setShowBannerFormModal] = useState(false);

  const {
    data: bannersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['banners'],
    queryFn: () => apiGenerator('get')('/admin/banners'),
  });

  const destroyBannerMutation = useMutation({
    mutationFn: (id) => apiGenerator('delete')(`/admin/banners/${id}`),
    onSuccess: () => {
      refetch();
    },
  });

  const increaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/banners/${id}/increase-order`),
    onSuccess: () => {
      refetch();
    },
  });

  const decreaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/banners/${id}/decrease-order`),

    onSuccess: () => {
      refetch();
    },
  });

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const rows =
    bannersData?.data?.map((banner) => ({
      id: banner.id,
      order: banner.attributes.order,
      image_url: banner.attributes.image_url,
    })) || [];

  const columns = [
    { field: 'order', headerName: 'Order', sortable: false, flex: 0.2 },
    {
      field: 'image_url',
      headerName: 'Image',
      renderCell: ({ row }) => (
        <Box sx={{ width: 60, height: 60, borderRadius: 1, overflow: 'hidden' }}>
          <img src={row.image_url} alt="no-banner" />
        </Box>
      ),
      flex: 0.2,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.1,
      sortable: false,
      renderCell: ({ row }) => (
        <Box display={'flex'}>
          <IconButton
            onClick={() => {
              increaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            onClick={() => {
              decreaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setOpen(e.currentTarget);
              setSelectedBanner(row);
            }}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Banners | Petzip</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banners
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setShowBannerFormModal(true);
            }}
          >
            New Banner
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter rowHeight={100} />
            </div>
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            console.log(open.id);
            destroyBannerMutation.mutate(selectedBanner.id);
            setOpen(null);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {showBannerFormModal && (
        <BannerFormModal
          open={showBannerFormModal}
          setOpen={setShowBannerFormModal}
          onSuccess={() => {
            setShowBannerFormModal(false);
            refetch();
          }}
        />
      )}
    </>
  );
}
