import { useEffect } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const GuestRoute = () => {
  const outlet = useOutlet();
  const { user, getUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      getUser();
    }

    if (user) {
      navigate('/');
    }
  }, [user]);

  return outlet;
};
