import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Container,
  Typography,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, MoreHoriz, OpenInNew } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import LessonCategoryFormModal from 'components/LessonCategoryFormModal/LessonCategoryFormModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

export default function LessonCategoriesPage() {
  const [open, setOpen] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [refetchCount, setRefetchCount] = useState(0);
  const [showCategoryFormModal, setShowCategoryFormModal] = useState(false);
  const [parentCategory, setParentCategory] = useState(null);

  const navigate = useNavigate();

  const { data: categoriesData, isLoading } = useQuery({
    queryKey: ['lesson-categories', refetchCount, parentCategory?.id],
    queryFn: () =>
      apiGenerator('get')('/admin/lesson-categories', {
        params: {
          parent_category_id: parentCategory?.id,
        },
      }),
  });

  const destroyCategoryMutation = useMutation({
    mutationFn: (id) => apiGenerator('delete')(`/admin/lesson-categories/${id}`),
    onSuccess: () => {
      setRefetchCount((prev) => prev + 1);
    },
  });

  const increaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/lesson-categories/${id}/increase-order`),
    onSuccess: () => {
      setRefetchCount((prev) => prev + 1);
    },
  });

  const decreaseOrderMutation = useMutation({
    mutationFn: (id) => apiGenerator('put')(`/admin/lesson-categories/${id}/decrease-order`),

    onSuccess: () => {
      setRefetchCount((prev) => prev + 1);
    },
  });

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const rows =
    categoriesData?.data?.map((category) => ({
      id: category.id,
      order: category.attributes.order,
      title: category.attributes.title,
      description: category.attributes.description,
      premium: category.attributes.premium,
      banner_url: category.attributes.banner_url,
      has_sub_categories: category.attributes.has_sub_categories,
    })) || [];

  const columns = [
    { field: 'id', headerName: 'ID', width: 0 },
    { field: 'order', headerName: 'Order', sortable: false, flex: 0.2 },
    {
      field: 'banner_url',
      headerName: 'Banner',
      renderCell: ({ row }) => (
        <Box sx={{ width: 60, height: 60, borderRadius: 1, overflow: 'hidden' }}>
          <img src={row.banner_url} alt="no-banner" />
        </Box>
      ),
      flex: 0.2,
    },

    { field: 'title', headerName: 'Title', flex: 0.2 },
    { field: 'description', headerName: 'Description', flex: 0.2 },
    { field: 'premium', headerName: 'Premium', flex: 0.2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.3,
      sortable: false,
      renderCell: ({ row }) => (
        <Box display={'flex'}>
          <IconButton
            onClick={() => {
              if (row.has_sub_categories) {
                setParentCategory(row);
              } else {
                navigate(`/lesson-categories/${row.id}`);
              }
            }}
          >
            <OpenInNew />
          </IconButton>
          <IconButton
            onClick={() => {
              increaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            onClick={() => {
              decreaseOrderMutation.mutate(row.id);
            }}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setOpen(e.currentTarget);
              setSelectedCategory(row);
            }}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Categories | Petzip</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {parentCategory ? `Category: ${parentCategory.title}` : 'Categories'}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setSelectedCategory(null);
              setShowCategoryFormModal(true);
            }}
          >
            New Category
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} hideFooter rowHeight={100} />
            </div>
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpen(null);
            setShowCategoryFormModal(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            destroyCategoryMutation.mutate(selectedCategory.id);
            setOpen(null);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <LessonCategoryFormModal
        open={showCategoryFormModal}
        setOpen={setShowCategoryFormModal}
        onSuccess={() => {
          setRefetchCount((prev) => prev + 1);
        }}
        lessonCategory={selectedCategory}
        parentCategory={parentCategory}
      />

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
