import { Navigate, useRoutes } from 'react-router-dom';
import { GuestRoute } from 'components/GuestRoute';
import LessonCategoriesPage from 'pages/LessonCategoriesPage';
import LessonShowPage from 'pages/LessonShowPage';
import LessonCategoryShowPage from 'pages/LessonCategoryShowPage';
import LessonPhaseShowPage from 'pages/LessonPhaseShowPage';
import BannersPage from 'pages/BannersPage';
import ChatsPage from 'pages/ChatsPage';
import ChatPage from 'pages/ChatPage';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

export default function Router() {
  const routes = useRoutes([
    {
      element: <GuestRoute />,
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },
    {
      element: <AuthenticatedRoute />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/lesson-categories" />, index: true },
            { path: 'lesson-categories', element: <LessonCategoriesPage /> },
            { path: 'lesson-categories/:id', element: <LessonCategoryShowPage /> },
            { path: 'lessons/:id', element: <LessonShowPage /> },
            { path: 'lesson-phases/:id', element: <LessonPhaseShowPage /> },
            { path: 'banners', element: <BannersPage /> },
            { path: 'chats', element: <ChatsPage /> },
            { path: 'chats/:id', element: <ChatPage /> },
          ],
        },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
