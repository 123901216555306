import React, { useEffect } from 'react';
import {  useOutlet, useNavigate } from 'react-router-dom';
import { getAccessTokenFromLocalStorage } from 'helpers/storage.helpers';
import { useAuth } from '../hooks/useAuth';

export const AuthenticatedRoute = () => {
  const outlet = useOutlet();
  const { getUser } = useAuth();
  const navigate = useNavigate();

  const token = getAccessTokenFromLocalStorage();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }

    getUser();

    return () => {};
  }, []);

  return outlet;
};
